import { useTranslation } from "react-i18next";
import { useSortBy } from "react-instantsearch";
import { useIndexName } from "hooks/useIndexName";

import ArrowRightIcon from "assets/icons/arrow_right_light_icon.svg";

const SortableHeader = ({
  label,
  algoliaKey,
  sortKey,
}: {
  label: string;
  algoliaKey: string;
  sortKey: string;
}) => {
  const indexName = useIndexName();
  const { t } = useTranslation();
  const { options, currentRefinement, refine } = useSortBy({
    items: [
      { value: `${algoliaKey}_desc`, label: "desc" },
      { value: `${algoliaKey}_asc`, label: "asc" },
      { value: `${indexName}`, label: "default" },
    ],
  });

  const handleSortClick = () => {
    const currentIndex = options.findIndex((item) => item.value === currentRefinement);
    const nextIndex = (currentIndex + 1) % options.length;
    refine(options[nextIndex].value);
  };

  const getSortIconClass = () => {
    return options.find((item) => item.value === currentRefinement)?.label || "default";
  };

  return (
    <div className={`sortable ${sortKey}`} onClick={handleSortClick}>
      <div className="headerContent">
        <div className="headerText">{t(label)}</div>
        <img src={ArrowRightIcon} alt="sort icon" className={`sortIcon ${getSortIconClass()}`} />
      </div>
    </div>
  );
};

export default SortableHeader;
