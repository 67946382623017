import apiClient from "./apiClient";

const SNOOZE_HOURS = 12;

export const updateNotification = async (
  accessToken: string,
  notificationId: string,
  action: "dismiss" | "snooze",
) => {
  try {
    let data = {};
    if (action === "dismiss") {
      data = { viewStatus: "closed" };
    } else if (action === "snooze") {
      const snoozeUntil = new Date();
      snoozeUntil.setHours(snoozeUntil.getHours() + SNOOZE_HOURS);
      data = { snoozeUntil: snoozeUntil.toISOString() };
    }

    const res = await apiClient.patch(`/alerts/notifications/${notificationId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return res.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error updating notification:", error);
    throw error;
  }
};
