import { FilterDisplay, FilterRow } from "./CurrentFilters.styled";
import { useClearRefinements, useCurrentRefinements } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import FilterButton from "features/Tracking/components/FilterButton";
import FilterIcon from "assets/icons/filter.svg";
import { capitalize } from "utils/string";

interface CurrentFiltersProps {
  attributesDisplay: Record<string, string>;
}

const CurrentFilters = ({ attributesDisplay }: CurrentFiltersProps) => {
  const { items: filters, refine: clearFacet } = useCurrentRefinements({
    excludedAttributes: ["archived", "query"],
  });
  const { refine: clearAllFilters } = useClearRefinements();
  const { t } = useTranslation();

  return (
    <FilterDisplay>
      {filters.length > 0 && (
        <>
          <div className="titleSection">
            <img src={FilterIcon} alt="Filter" />
            <span>{t("Active Filters")}</span>
          </div>
          <FilterRow>
            <FilterButton
              text={t("Clear All")}
              active
              onClick={clearAllFilters}
              limitWidth={false}
            />
          </FilterRow>
          {filters.map((filter, index) => (
            <FilterRow
              key={`${filter.label}${index}`}
              className={index === filters.length - 1 ? "noBorder" : ""}
            >
              <FilterButton
                text={attributesDisplay[filter.attribute] || filter.label}
                active
                onClick={() => {
                  filter.refinements.forEach((refinement) => {
                    clearFacet(refinement);
                  });
                }}
                limitWidth={false}
              />
              {filter.refinements.map((refinement, i) => (
                <FilterButton
                  key={`${filter.label}${refinement.value}${i}`}
                  text={capitalize(refinement.label)}
                  active
                  onClick={() => clearFacet(refinement)}
                  limitWidth={false}
                />
              ))}
            </FilterRow>
          ))}
        </>
      )}
    </FilterDisplay>
  );
};

export default CurrentFilters;
