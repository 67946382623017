import apiClient from "./apiClient";
import { CargoUnitDetails } from "types/cargoUnitTypes";

export async function getCargoUnitDetails(
  accessToken: string,
  cargoUnitId: string | null,
): Promise<CargoUnitDetails | null> {
  if (!cargoUnitId) return null;

  const res = await apiClient.get<CargoUnitDetails>(`/journeys/records/${cargoUnitId}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...res.data };
}
