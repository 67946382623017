import { Fragment, useEffect, useCallback, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PDFIcon from "assets/icons/pdf_icon.svg";
import ExcelIcon from "assets/icons/excel_icon.svg";
import WordIcon from "assets/icons/word_icon.svg";
import ArrowRightLightIcon from "assets/icons/arrow_right_light_icon.svg";
import DocumentsIcon from "assets/icons/documents_icon.svg";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import { useFileURL } from "hooks/useFileURL";
import CloseIcon from "assets/icons/close_icon.svg";
import ContainerIcon from "assets/icons/v2/container_icon.svg";
import ETALogsImage from "assets/images/eta_logs.png";
import {
  Alert,
  AlertsTitlesSection,
  CargoUnitSection,
  CloseButton,
  Drawer,
  DrawerContent,
  AlertsHeader,
  ETALogsButton,
  LogsViewer,
} from "./TripCardModal.styled";
import MoreDetailsDrawer from "components/MoreDetailsDrawer";
import { useActiveAlertsStore } from "store/activeAlertsStore";
import { getTripDetails } from "services/api/tracking";
import { TripCargoUnit, TripDetails } from "types/trackingTypes";
import { formatDate } from "utils/date";
import { alertsMessages } from "features/Tracking/constants/alerts";
import AlertsSection from "./components/AlertsSection";
import { useTripStore } from "store/trackingStore";
import CargoUnitDetails from "./components/CargoUnitDetails";
import FilePreview from "./components/FilePreview";
import { hasExcelExtension, hasWordExtension } from "utils/string";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";

const DEFAULT_WIDTH = "574px";
const HIDDEN_WIDTH = "0px";

type StringBooleanMap = {
  [key: string]: boolean;
};

const TripCardModal = ({ accessToken }: WithLoggedInAuthInfoProps) => {
  const [openAlertsSection, setOpenAlertsSection] = useState<boolean>(false);
  const [drawerWidth, setDrawerWidth] = useState<string>("0px");
  const drawerRef = useRef<HTMLDivElement>(null);
  const { clearActiveAlerts } = useActiveAlertsStore();
  const { tripId, clearTripId } = useTripStore();
  const [file, setFile] = useState<{ fileId: string; fileName: string }>({
    fileId: "",
    fileName: "",
  });
  const { fileURL, setFileURL } = useFileURL(file.fileId, accessToken);
  const [expandedDivs, setExpandedDivs] = useState<StringBooleanMap>({});
  const [cargoUnit, setCargoUnit] = useState<TripCargoUnit>();
  const [cargoUnitId, setCargoUnitId] = useState<string>("");
  const [openCargoUnitSection, setOpenCargoUnitSection] = useState<boolean>(false);
  const [openETALogsViewer, setOpenETALogsViewer] = useState<boolean>(false);
  const featureFlags = useFeatureFlags(accessToken);
  const isETALogsEnabled = featureFlags[FeatureFlagNames.EnableETALogsFrontend];

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    data: tripData = {} as TripDetails,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getTripDetails(accessToken, tripId),
    queryKey: ["getTripDetails", accessToken, tripId],
    enabled: tripId !== null,
  });

  const { documents, alerts = [], vendor, billOfLading, cargoUnits } = tripData?.data || {};
  const resetState = useCallback(() => {
    clearActiveAlerts();
    clearTripId();
    setFile({ fileId: "", fileName: "" });
    setFileURL("");
    setCargoUnitId("");
    setExpandedDivs({});
    setOpenAlertsSection(false);
    setOpenCargoUnitSection(false);
    setOpenETALogsViewer(false);
  }, [clearActiveAlerts, clearTripId, setFileURL]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        resetState();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resetState]);

  useEffect(() => {
    if (tripId && (file.fileId || openAlertsSection || openCargoUnitSection || openETALogsViewer)) {
      setDrawerWidth("80%");
    } else if (
      tripId &&
      !file.fileId &&
      !openAlertsSection &&
      !openCargoUnitSection &&
      !openETALogsViewer
    ) {
      setDrawerWidth(DEFAULT_WIDTH);
    } else {
      setDrawerWidth(HIDDEN_WIDTH);
    }
  }, [file.fileId, tripId, openAlertsSection, openCargoUnitSection, openETALogsViewer]);

  useEffect(() => {
    if (alerts?.length === 0) {
      setOpenAlertsSection(false);
    }
  }, [alerts]);

  const toggleExpand = (category: string): void => {
    setExpandedDivs((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const getFileIcon = (fileName: string) => {
    if (hasExcelExtension(fileName)) {
      return ExcelIcon;
    } else if (hasWordExtension(fileName)) {
      return WordIcon;
    } else {
      return PDFIcon;
    }
  };

  if (isLoading || isError) return null;

  return (
    <Drawer ref={drawerRef} width={drawerWidth}>
      <CloseButton onClick={resetState} aria-label="Close">
        <img src={CloseIcon} alt="Close Icon" />
      </CloseButton>
      <DrawerContent data-testid="trip-card-modal">
        <div className="vendor">
          <div className="header">
            <div className="column">
              <h4>{t("Vendor")}</h4>
            </div>
            <div className="column">
              <h4>{t("Bill of lading")}</h4>
            </div>
          </div>
          <div className="vendorDetails row">
            <div className="column">
              <div>{vendor}</div>
            </div>
            <div className="column">
              <div>{billOfLading}</div>
            </div>
          </div>
        </div>
        <CargoUnitSection>
          <div className="header">
            <div className="column">
              <div>{t("Container")}</div>
            </div>
            <div className="column">
              <div>{t("SKU Count")}</div>
            </div>
            <div className="column">
              <div>{t("Final ETA")}</div>
            </div>
          </div>
          <div className="cargoUnitList">
            {cargoUnits && cargoUnits.length > 0 && (
              <>
                {cargoUnits.map((cargoUnit, i) => (
                  <div
                    key={i}
                    className={`row ${cargoUnit.referenceId === cargoUnitId ? "active" : ""}`}
                    onClick={() => {
                      setCargoUnitId(cargoUnit.referenceId);
                      setCargoUnit(cargoUnit);
                      setFile({ fileId: "", fileName: "" });
                      setFileURL("");
                      setOpenCargoUnitSection(true);
                      setOpenAlertsSection(false);
                      setOpenETALogsViewer(false);
                    }}
                  >
                    <div className="column containerNumber">
                      <img src={ContainerIcon} alt="CargoUnit Icon" />
                      <span>{cargoUnit.referenceId}</span>
                    </div>
                    <div className="column">{cargoUnit.lineItems?.length}</div>
                    <div className={`column ${cargoUnit.finalLegAta ? "ata" : "eta"}`}>
                      {cargoUnit.finalLegEta && (
                        <>
                          {formatDate(new Date(cargoUnit.finalLegEta), language)}
                          {cargoUnit.finalLegAta && <div className="tag ata">{t("Arrived")}</div>}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </CargoUnitSection>
        <div className="documents">
          <div className="header">
            <h4>{t("Received on")}</h4>
          </div>
          <div className="documentsList">
            {documents?.map((document, i) => (
              <div key={i}>
                <div key={document.type} className="documentType row">
                  <span className="label">{document.type}</span>
                  <img
                    className={`arrowIcon ${expandedDivs[document.type] && "open"}`}
                    src={ArrowRightLightIcon}
                    alt="arrowIcon"
                    onClick={() => {
                      toggleExpand(document.type);
                    }}
                  />
                </div>
                {expandedDivs[document.type] && (
                  <div>
                    {document.documents.map(({ referenceId, versions }, index) => (
                      <Fragment key={index}>
                        <div
                          className="referenceId row"
                          key={referenceId}
                          onClick={() => {
                            setFile({
                              fileId: versions[0].fileId,
                              fileName: versions[0].fileName,
                            });
                            setCargoUnitId("");
                            setOpenAlertsSection(false);
                            setOpenCargoUnitSection(false);
                            setOpenETALogsViewer(false);
                          }}
                        >
                          <span className="label">
                            <img
                              src={getFileIcon(versions[0].fileName)}
                              alt="File Icon"
                              className="fileIcon"
                            />
                            {document.type === "Other" ? versions[0].fileName : referenceId}
                          </span>
                          {document.type !== "Other" && (
                            <img
                              className={`arrowIcon ${expandedDivs[referenceId] && "open"}`}
                              src={ArrowRightLightIcon}
                              alt="arrowIcon"
                              onClick={() => {
                                toggleExpand(referenceId);
                              }}
                            />
                          )}
                        </div>
                        {document.type !== "Other" && expandedDivs[referenceId] && (
                          <div>
                            {versions.map((docVersion, j) => (
                              <div key={j}>
                                <div
                                  className="fileName row"
                                  key={j}
                                  onClick={() => {
                                    setFile({
                                      fileId: docVersion.fileId,
                                      fileName: docVersion.fileName,
                                    });
                                    setOpenAlertsSection(false);
                                    setOpenCargoUnitSection(false);
                                    setOpenETALogsViewer(false);
                                  }}
                                >
                                  <span className="label">
                                    <img
                                      src={getFileIcon(docVersion.fileName)}
                                      alt="File Icon"
                                      className="fileIcon"
                                    />
                                    {docVersion.fileName}
                                  </span>
                                  <span className="uploadDate">
                                    {formatDate(new Date(docVersion.uploadDate), language)}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {alerts && alerts.length > 0 && (
          <AlertsTitlesSection
            onClick={() => {
              setFile({ fileId: "", fileName: "" });
              setFileURL("");
              setOpenAlertsSection(true);
              setOpenCargoUnitSection(false);
              setCargoUnitId("");
            }}
            className="alerts"
          >
            <AlertsHeader>
              <span className="numberOfAlerts">{alerts.length}</span>
              <span>{t("Alerts")}</span>
            </AlertsHeader>
            <>
              {alerts.map((alert, i) => (
                <Alert key={i}>
                  <div className="eventAlert">
                    <div className="alertTitle alert">
                      <div className="iconBlock">
                        <img src={DocumentsIcon} alt="Documents Icon" />
                      </div>
                      <p>{t(alertsMessages[alert.topic][alert.mostRecentEvent] as string)}</p>
                    </div>
                  </div>
                </Alert>
              ))}
            </>
          </AlertsTitlesSection>
        )}
        {isETALogsEnabled && (
          <ETALogsButton
            onClick={() => {
              setOpenETALogsViewer(true);
              setFile({ fileId: "", fileName: "" });
              setFileURL("");
              setOpenAlertsSection(false);
              setOpenCargoUnitSection(false);
            }}
          >
            {t("See ETA Logs")}
          </ETALogsButton>
        )}
      </DrawerContent>

      <MoreDetailsDrawer
        show={!!file.fileId}
        onClose={() => {
          setFile({ fileId: "", fileName: "" });
          setFileURL("");
        }}
      >
        <FilePreview fileId={file.fileId} fileName={file.fileName} fileURL={fileURL} />
      </MoreDetailsDrawer>
      <MoreDetailsDrawer show={openAlertsSection} onClose={() => setOpenAlertsSection(false)}>
        <AlertsSection alerts={alerts} />
      </MoreDetailsDrawer>
      <MoreDetailsDrawer
        show={openCargoUnitSection}
        onClose={() => {
          setCargoUnitId("");
          setOpenCargoUnitSection(false);
        }}
      >
        {cargoUnit !== undefined && <CargoUnitDetails cargoUnit={cargoUnit}></CargoUnitDetails>}
      </MoreDetailsDrawer>
      <MoreDetailsDrawer show={openETALogsViewer} onClose={() => setOpenETALogsViewer(false)}>
        <LogsViewer>
          <h3>{t("ETA Logs")}</h3>
          <div className="content">
            <img src={ETALogsImage} alt="ETA Logs" />
          </div>
          <div className="footer" />
        </LogsViewer>
      </MoreDetailsDrawer>
    </Drawer>
  );
};

export default withAuthInfo(TripCardModal);
