import { Index, useInfiniteHits } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useOceanViewColumns } from "features/OceanView/hooks/useOceanViewColumns";
import ActionButtons from "features/Tracking/components/ActionButtons";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import Search from "features/Tracking/components/Search";
import CargoUnitCard from "features/CargoUnitCard";
import FilterDropdown from "components/FilterDropdown";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import CurrentFilters from "components/CurrentFilters";
import JourneysTable from "components/JourneysTable";
import { sanitizeAlgoliaCargoUnits } from "utils/sanitizeAlgoliaRecords";
import SearchPagination from "components/SearchPagination";
import { attributesDisplay, filterAttributes } from "features/OceanView/constants/filters";

const OCEAN_FILTER_CONFIG = "AND (entryModeOfTransport:ocean OR currentModeOfTransport:ocean)";
const AlgoliaOceanViewTable = () => {
  const { items } = useInfiniteHits();
  const columns = useOceanViewColumns();
  const {
    i18n: { language },
  } = useTranslation();

  const cargoUnitRecords = sanitizeAlgoliaCargoUnits(items, language);

  return (
    <>
      <JourneysTable journeys={cargoUnitRecords} columns={columns} />
      <SearchPagination />
    </>
  );
};

const OceanView = () => {
  const { t } = useTranslation();

  useDocumentTitle(t("Ocean View"));

  return (
    <IndexNameProvider value={ALGOLIA_INDEXES.JOURNEY}>
      <Index indexName={ALGOLIA_INDEXES.JOURNEY}>
        <AlgoliaConfigure filters={OCEAN_FILTER_CONFIG} />
        <ActionButtons title="Ocean">
          <Search />
          <FilterDropdown filterAttributes={filterAttributes} />
          <ArchivedButton />
        </ActionButtons>
        <CurrentFilters attributesDisplay={attributesDisplay} />
        <AlgoliaOceanViewTable />
        <CargoUnitCard />
      </Index>
    </IndexNameProvider>
  );
};

export default OceanView;
