import styled from "@emotion/styled";

export const FileViewer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;

  iframe {
    height: 680px;
    border: 10px solid #323639;
    border-top: 30px solid #323639;
    border-radius: 14px 0px 0px 14px;
    width: 100%;
    height: 90%;
  }
`;

export const NotSupported = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 20px;
  color: #323639;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .fileIcon {
    width: 72px;
    height: 74px;
  }
`;
