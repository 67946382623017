import styled from "@emotion/styled";

export const Panel = styled.div`
  height: 80vh;
  overflow: auto;
  font-size: 14px;
  border-radius: 16px;
  border: 1px solid #cacaca;
  padding: 11px;

  .header {
    display: flex;
    justify-content: flex-end;
    padding-right: 47px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .documentsList {
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        background: #f5f3f3;
      }

      &.active {
        background: #f5f3f3;
      }
    }
    img {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &.open {
        transform: rotate(90deg);
      }
      &.fileIcon {
        padding-right: 5px;
        width: 14px;
        height: 11px;
      }
    }
    .label {
      margin-right: 17px;
      margin-left: 19px;
    }
    .documentType {
      background: #f8f8f8;
      font-size: 15px;
      height: 37px;
      box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
    }
    .referenceId {
      gap: 3px;
      height: 23px;
    }
    .fileName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 23px;
      padding-left: 40px;
      padding-right: 57px;
    }
  }
`;
