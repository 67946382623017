import { useRef } from "react";
import { flexRender, Table } from "@tanstack/react-table";
import { CargoUnit } from "types/cargoUnitTypes";
import { TableHeader } from "./TableHeader.styled";

const TableHeaders = ({ table }: { table: Table<CargoUnit> }) => {
  const columnSizerRef = useRef<Record<string, HTMLElement | null>>({});

  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeader
              key={header.id}
              columnWidth={header.column.getSize()}
              columnStart={header.column.getStart("left")}
              ref={(element) => {
                columnSizerRef.current[header.id] = element;
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              <div
                className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
              />
            </TableHeader>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeaders;
