import { useEffect, RefObject } from "react";

export const useKeyScroll = (containerRef: RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (containerRef.current) {
        const { scrollTop, scrollLeft } = containerRef.current;

        switch (event.key) {
          case "ArrowUp":
            event.preventDefault();
            containerRef.current.scrollTo({
              top: scrollTop - 50,
              behavior: "smooth",
            });
            break;
          case "ArrowDown":
            event.preventDefault();
            containerRef.current.scrollTo({
              top: scrollTop + 50,
              behavior: "smooth",
            });
            break;
          case "ArrowLeft":
            event.preventDefault();
            containerRef.current.scrollTo({
              left: scrollLeft - 50,
              behavior: "smooth",
            });
            break;
          case "ArrowRight":
            event.preventDefault();
            containerRef.current.scrollTo({
              left: scrollLeft + 50,
              behavior: "smooth",
            });
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [containerRef]);
};
