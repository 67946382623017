import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { CargoUnit } from "types/cargoUnitTypes";
import UntrackedTooltip from "components/UntrackedTooltip";
import SortableHeader from "components/SortableHeader";
import { normalizeText } from "utils/string";

export const useOceanViewColumns = () => {
  const { t } = useTranslation();

  const translate = useCallback((key: string) => t(key) as string, [t]);

  const columns = useMemo<ColumnDef<CargoUnit>[]>(
    () => [
      {
        header: translate("ID"),
        accessorKey: "id",
      },
      {
        accessorKey: "cargoId",
        header: translate("Container Number"),
      },
      {
        accessorKey: "oceanCarrier",
        header: translate("Carrier"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        header: translate("Vessel Name"),
        accessorKey: "vesselName",
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "entryBLNumbers",
        header: translate("Bill of Lading Numbers"),
      },
      {
        accessorKey: "latestOceanMilestone",
        header: translate("Latest Milestone"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "purchaseOrderNumbers",
        header: translate("Purchase Orders"),
      },
      {
        accessorKey: "invoiceNumbers",
        header: translate("Invoice Numbers"),
      },
      {
        accessorKey: "vendor",
        header: translate("Vendor"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "departureCountry",
        header: translate("Departure Country"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "departureCity",
        header: translate("Port of Departure"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "endpointCountry",
        header: translate("Destination Country"),
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "endpointCity",
        header: "Port of Destination",
        cell: (props) => normalizeText(props.getValue<string>()),
      },
      {
        accessorKey: "departureETA",
        header: () => (
          <SortableHeader
            label="Estimated Departure Date"
            algoliaKey="journey_ocean_departure_eta"
            sortKey="departureETA"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.departureUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        accessorKey: "departureDate",
        header: () => (
          <SortableHeader
            label="Actual Departure Date"
            algoliaKey="journey_departure_date"
            sortKey="departureDate"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.departureUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        accessorKey: "entryOriginalETA",
        header: () => (
          <SortableHeader
            label="Original Discharge ETA"
            algoliaKey="journey_original_discharge_eta"
            sortKey="originalDischargeETA"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.entryUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        accessorKey: "entryCurrentETA",
        header: () => (
          <SortableHeader
            label="Current Discharge ETA"
            algoliaKey="journey_current_discharge_eta"
            sortKey="currentDischargeETA"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.entryUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        accessorKey: "entryDestinationATA",
        header: () => (
          <SortableHeader
            label="Port of Destination ATA"
            algoliaKey="journey_ocean_arrival_ata"
            sortKey="destinationPortATA"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.entryUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        accessorKey: "entryATA",
        header: () => (
          <SortableHeader
            label="Actual Discharge Date"
            algoliaKey="journey_entry_ata"
            sortKey="dischargeDateATA"
          />
        ),
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.entryUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        header: translate("Total ETA Delay (days)"),
        accessorKey: "totalETADelay",
      },
    ],
    [translate],
  );

  return columns;
};
