import styled from "@emotion/styled";

export const Drawer = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  transition: width 0.4s ease;
  overflow: hidden;
  border-top-left-radius: 25px;
  display: flex;
  border-radius: 16px 0px 0px 16px;
  background: var(--Primary-Base-White, #fff);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  left: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 2;
`;

export const DrawerContent = styled.div`
  padding: 33px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

export const TabList = styled.div`
  display: flex;
  gap: 48px;
  margin-bottom: 20px;
`;

export const Tab = styled.button<{ active?: boolean }>`
  border: none;
  border-bottom: 3px solid ${(props) => (props.active ? "#4CDBCA" : "transparent")};
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  padding: 0;
`;

export const TabPanel = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
