import styled from "@emotion/styled";

export const TableHeader = styled.th<{ columnWidth: number; columnStart: number }>`
  width: ${(props) => props.columnWidth}px;
  position: relative;
  border: 1px solid #e9e9ee;
  padding: 5px;
  overflow: hidden;
  z-index: auto;

  &:first-of-type {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-top-left-radius: 16px;
  }
  &:nth-of-type(2) {
    position: sticky;
    left: ${(props) => props.columnStart}px;
    z-index: 1;
    background-color: white;
  }
  &:last-child {
    border-top-right-radius: 16px;
  }

  .resizer {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    cursor: col-resize;
    user-select: none;
    touchaction: none;
    border-radius: 6px;
    background: #c39ad1;

    &.isResizing {
      background-color: #c39ad8;
      opacity: 1;
    }
  }
  &:hover > .resizer {
    opacity: 1;
  }

  .headerContent {
    display: flex;
    align-items: center;
    flex-direction: row;

    .headerText {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .sortable {
    cursor: pointer;

    img.asc {
      transform: rotate(-90deg);
    }

    img.desc {
      transform: rotate(90deg);
    }
  }
  .sortIcon {
    margin: 0 10px;
  }
`;
