import { setCookie } from "utils/cookies";
import { create } from "zustand";

type ActiveTenantStore = {
  activeTenant: string;
  setActiveTenant: (activeTenant: string, shouldReload?: boolean) => void;
};

export const useActiveTenantStore = create<ActiveTenantStore>((set) => ({
  activeTenant: "",
  setActiveTenant: (activeTenant = "", shouldReload = true) => {
    setCookie("activeTenant", activeTenant);
    set({ activeTenant });
    if (shouldReload) {
      window.location.reload();
    }
  },
}));
