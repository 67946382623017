import apiClient from "./apiClient";

export async function getUserInfo(accessToken: string): Promise<{
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  language?: "english" | "spanish";
  accessibleTenants?: { id: string; name: string; humanReadableName: string }[];
}> {
  const res = await apiClient.get("/config/user-info", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
}

export async function getTenantInfo(accessToken: string): Promise<{
  id: string;
  name: string;
  humanReadableName: string;
  profilePhoto: { content?: string; contentType?: string };
  logoutUrl: string;
  usesDivisions: boolean;
}> {
  const res = await apiClient.get("/config/tenant-info", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
}

export async function getLocalFeatureFlags(accessToken: string) {
  const res = await apiClient.get("/config/feature-flags", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
}

export async function updateUserInfo(
  accessToken: string,
  data: {
    firstName?: string;
    lastName?: string;
    language?: "english" | "spanish";
  },
) {
  const res = await apiClient.patch("/config/user-info", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
}
