import { ALGOLIA_INDEXES, IndexName } from "hooks/useIndexName";
import apiClient from "./apiClient";

// Define the endpoints to export records depending on the Algolia index
export const indexEndpoints = {
  [ALGOLIA_INDEXES.TRIP]: "tracking",
  [ALGOLIA_INDEXES.JOURNEY]: "journeys",
};

export async function exportRecords(
  accessToken: string,
  index: IndexName,
  archived: boolean = false,
  searchValue: string = "",
  refinementList = {},
) {
  const res = await apiClient.get<string>(`/${indexEndpoints[index]}/records/export`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      archived,
      searchValue,
      refinementList,
    },
    responseType: "blob",
  });
  return res.data;
}
