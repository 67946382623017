import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .content {
    overflow-y: auto;
    height: calc(100vh - 100px);

    img {
      width: 100%;
    }
  }

  h1 {
    margin-bottom: 20px;
  }
`;
