import { FilterAttributes } from "types/filterTypes";

export const attributesDisplay: Record<string, string> = {
  oceanCarrier: "Carrier",
  vendor: "Vendor",
  departureCountry: "Departure Country",
  departureCity: "Port of Departure",
  endpointCountry: "Destination Country",
  endpointCity: "Port of Destination",
};

export const filterAttributes: FilterAttributes[] = [
  {
    title: "Ocean Records",
    attributes: [
      { attribute: "oceanCarrier", display: attributesDisplay.oceanCarrier },
      { attribute: "vendor", display: attributesDisplay.vendor },
      { attribute: "departureCountry", display: attributesDisplay.departureCountry },
      { attribute: "departureCity", display: attributesDisplay.departureCity },
      { attribute: "endpointCountry", display: attributesDisplay.endpointCountry },
      { attribute: "endpointCity", display: attributesDisplay.endpointCity },
    ],
  },
];
