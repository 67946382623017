import Cookies from "js-cookie";

export const setCookie = (name: string, value: string, options = {}) => {
  Cookies.set(name, value, { domain: window.location.hostname, path: "/", ...options });
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const removeCookie = (name: string) => {
  Cookies.remove(name, { domain: window.location.hostname, path: "/" });
};

export const removeAllCookies = () => {
  const allCookies = Cookies.get();
  for (const cookie in allCookies) {
    Cookies.remove(cookie, { domain: window.location.hostname, path: "/" });
  }
};
