import { useTranslation } from "react-i18next";
import { useInstantSearch, useSearchBox, useToggleRefinement } from "react-instantsearch";
import { useIndexName } from "hooks/useIndexName";
import config from "config";
import { exportRecords, indexEndpoints } from "services/api/export";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import ExcelIcon from "assets/icons/excel_icon.svg";
import ExcelHoverIcon from "assets/icons/excel_hover_icon.svg";

const { USE_BACKEND_RECORDS } = config.envs;

const ExcelExportButton = ({ accessToken }: WithLoggedInAuthInfoProps) => {
  const indexName = useIndexName();
  const { t } = useTranslation();
  const { uiState } = useInstantSearch();
  const { query: searchValue } = useSearchBox();
  const {
    value: { isRefined: isArchived },
  } = useToggleRefinement({ attribute: "archived" });

  const downloadRecords = async () => {
    const data = await exportRecords(
      accessToken,
      indexName,
      isArchived,
      searchValue,
      uiState[indexName].refinementList,
    );
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    const include = isArchived ? "archived" : "active";

    link.href = url;
    const stringDate = new Date().toISOString().split("T")[0];
    link.download = `${indexEndpoints[indexName]}_${include}_${stringDate}.xlsx`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  return (
    <button
      type="button"
      className="excelButton"
      onClick={downloadRecords}
      disabled={USE_BACKEND_RECORDS === "true"}
    >
      <img src={ExcelIcon} alt="Excel icon" />
      <img src={ExcelHoverIcon} alt="Excel icon" className="hover" />
      <span>{t("Export")}</span>
    </button>
  );
};

export default withAuthInfo(ExcelExportButton);
