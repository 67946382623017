import config from "config";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import Dashboard from "components/MetabaseDashboard";
import { Container } from "./Inisights.styled";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import { withAuthInfo } from "@propelauth/react";

const {
  METABASE_DASHBOARD_ID,
  METABASE_SPANISH_DASHBOARD_ID,
  METABASE_JOURNEY_DASHBOARD_ID,
  METABASE_JOURNEY_SPANISH_DASHBOARD_ID,
} = config.envs;

const getDashboardId = (isJourneyDashboardEnabled: boolean, language: string): number => {
  if (isJourneyDashboardEnabled) {
    return Number(
      language === "es" ? METABASE_JOURNEY_SPANISH_DASHBOARD_ID : METABASE_JOURNEY_DASHBOARD_ID,
    );
  }
  return Number(language === "es" ? METABASE_SPANISH_DASHBOARD_ID : METABASE_DASHBOARD_ID);
};

const Insights = withAuthInfo(({ accessToken }) => {
  const featureFlags = useFeatureFlags(accessToken);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useDocumentTitle(t("Insights"));

  const dashboardId = getDashboardId(
    featureFlags[FeatureFlagNames.EnableJourneyAnalyticsDashboard],
    language,
  );

  return (
    <Container data-testid="insights-page">
      <h1>{t("Insights")}</h1>
      <Dashboard dashboardId={dashboardId} />
    </Container>
  );
});

export default Insights;
