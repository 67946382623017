import { useInfiniteHits } from "react-instantsearch";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchPagination = () => {
  const { hits, results, isLastPage, showMore } = useInfiniteHits();
  const { t } = useTranslation();
  return (
    <Box p={1} display="flex" alignItems="center" className="infinite-hits">
      <div>
        {t("Showing")} <strong>{hits.length}</strong> {t("out of")}{" "}
        <strong>{results?.nbHits}</strong> {t("shipments")}
      </div>
      <Button onClick={showMore} disabled={isLastPage}>
        {t("Show More")}
      </Button>
    </Box>
  );
};

export default SearchPagination;
