import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Index, useInfiniteHits, useToggleRefinement } from "react-instantsearch";
import { useQuery } from "@tanstack/react-query";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import config from "config";
import {
  attributesDisplay,
  filterAttributes,
  filterItemsDisplay,
} from "features/Tracking/constants/filters";
import { getTrackingRecords } from "services/api/tracking";
import { getTenantInfo } from "services/api/config";
import { sanitizeAlgoliaTrips } from "utils/sanitizeAlgoliaRecords";
import { TripHitProps } from "types/trackingTypes";
import FilterDropdown from "components/FilterDropdown";
import CurrentFilters from "components/CurrentFilters";
import SaveSearchButton from "components/SaveSearchButton";
import ActionButtons from "features/Tracking/components/ActionButtons";
import TrackingTable from "features/Tracking/components/TrackingTable";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import Search from "features/Tracking/components/Search";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import ExcelExportButton from "features/Tracking/components/ExcelExportButton.tsx";

const { USE_BACKEND_RECORDS } = config.envs;

const AlgoliaTrackingTable = () => {
  const { items } = useInfiniteHits<TripHitProps>();
  const tripsRecords = sanitizeAlgoliaTrips(items);

  return <TrackingTable trackingRecords={tripsRecords} />;
};

const BackendTrackingTable = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const {
    value: { isRefined: isArchived },
  } = useToggleRefinement({ attribute: "archived" });

  const { data: activeRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken),
    queryKey: ["trackingRecords"],
    enabled: !isArchived,
  });

  const { data: archivedRecords = [] } = useQuery({
    queryFn: () => getTrackingRecords(accessToken, true),
    queryKey: ["archivedRecords"],
    enabled: isArchived,
  });

  return <TrackingTable trackingRecords={isArchived ? archivedRecords : activeRecords} />;
});

const Tracking = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const { t } = useTranslation();
  useDocumentTitle(t("Tracking"));

  const { data: tenantInfo } = useQuery(["tenantInfo", accessToken], () =>
    getTenantInfo(accessToken),
  );

  // Remove division filter if tenant does not use divisions
  const filteredAttributes = useMemo(() => {
    if (tenantInfo?.usesDivisions) {
      return filterAttributes.map((filterAttribute) => ({
        ...filterAttribute,
        attributes: filterAttribute.attributes.filter(
          (attribute) => attribute.attribute !== "division",
        ),
      }));
    }
    return filterAttributes;
  }, [tenantInfo?.usesDivisions]);

  return (
    <IndexNameProvider value={ALGOLIA_INDEXES.TRIP}>
      <Index indexName={ALGOLIA_INDEXES.TRIP}>
        <AlgoliaConfigure />
        <ActionButtons title="Tracking">
          <Search />
          <SaveSearchButton />
          <FilterDropdown
            filterAttributes={filteredAttributes}
            filterItemsDisplay={filterItemsDisplay}
          />
          <ArchivedButton />
          <ExcelExportButton />
        </ActionButtons>
        <CurrentFilters attributesDisplay={attributesDisplay} />
        {USE_BACKEND_RECORDS === "true" ? <BackendTrackingTable /> : <AlgoliaTrackingTable />}
      </Index>
    </IndexNameProvider>
  );
});

export default Tracking;
