import styled from "@emotion/styled";

export const Navigation = styled.nav<{ collapsed: boolean }>`
  width: ${(props) => (props.collapsed ? "83px" : "196px")};
  transition: width 0.3s ease;
  height: 100vh;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 30px 30px 0;
  position: fixed;
  z-index: 999;

  @media (max-width: 844px), (max-height: 500px) {
    display: none;
  }

  .collapseButton {
    position: absolute;
    left: ${(props) => (props.collapsed ? "69px" : "180px")};
    top: ${(props) => (props.collapsed ? "89px" : "60px")};
    width: ${(props) => (props.collapsed ? "27px" : "33px")};
    height: ${(props) => (props.collapsed ? "27px" : "33px")};
    border-radius: 50%;
    background: white;
    border: #e9e9ee 1px solid;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .top {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 52px;
      cursor: pointer;

      img {
        width: 48px;
        height: 43px;
      }

      &.collapsed img {
        width: 42px;
        height: 30px;
      }
    }

    .orgInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      text-align: center;

      .orgName {
        font-size: 16px;
        font-weight: 600;
      }

      img {
        border-radius: 66px;
        border: 1px solid #00a59b;
        margin: 5px;
        width: 66px;
        height: 66px;

        &.collapsed {
          width: 55px;
          height: 55px;
        }
      }

      span {
        font-size: 14px;
      }
    }

    .menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .menuItem {
        width: 100%;
        padding: 12.5px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        position: relative;
        cursor: pointer;

        &.active {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 46px;
            bottom: 0;
            width: 8px;
            background-color: #00a59b;
          }
        }

        img {
          color: #fff;
          width: 26px;
        }
      }
    }
  }

  .options {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 16px;
    text-align: center;

    .content {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .icon {
        line-height: 10px;
      }
    }

    .langs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;

      .lang {
        color: #c2c2c2;
        font-size: ${(props) => (props.collapsed ? "12px" : "14px")};
        font-weight: 400;
        width: ${(props) => (props.collapsed ? "16px" : "32px")};
        height: 20px;
        background: rgba(172, 172, 172, 0.5);
        padding: 3px 8px;
        cursor: pointer;
      }

      .lang.en {
        border-radius: 8.167px 0px 0px 8.167px;
      }

      .lang.es {
        border-radius: 0px 8.167px 8.167px 0px;
      }

      .lang.active {
        background: #fefefe;
        color: #2a2a2a;
        font-weight: 600;
      }
    }

    .emailIcon {
      width: 16px;
    }

    .logoutIcon {
      width: 13px;
    }
  }

  .dropDown {
    position: relative;
    display: inline-block;

    p {
      font-size: ${(props) => (props.collapsed ? "12px" : "14px")};
    }

    button {
      border-radius: 3px;
      border: 1px solid #d9d9d9;
      background: #fdfdfd;
      padding: 5px 10px;
      cursor: pointer;
      width: ${(props) => (props.collapsed ? "80%" : "60%")};
      transition: all 0.3s ease;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropDownContent {
      position: absolute;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      background: #fdfdfd;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      min-width: 160px;
      z-index: 1;
      top: 0;
      left: ${(props) => (props.collapsed ? "80px" : "160px")};
      padding: 0;
      margin: 0;
      max-height: 138px;
      overflow-y: auto;

      li {
        color: black;
        text-align: left;
        font-size: 16px;
        color: #555;
        font-weight: 400;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        list-style: none;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid #ededf1;
        }

        &.active {
          background-color: #f1f1f1;
        }

        &:hover {
          color: #2a2a2a;
          background-color: #f1f1f1;
        }
      }
    }

    &:hover button {
      background: #f1f1f1;
    }
  }
`;
