import { create } from "zustand";

type TripStore = {
  tripId: string | null;
  setTripId: (tripId: string | null) => void;
  clearTripId: () => void;
};

export const useTripStore = create<TripStore>((set) => ({
  tripId: null,
  setTripId: (tripId) => set({ tripId }),
  clearTripId: () => set({ tripId: null }),
}));
