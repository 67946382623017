import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useActiveTenantStore } from "store/activeTenantStore";
import { WithLoggedInAuthInfoProps, useLogoutFunction, withAuthInfo } from "services/auth";
import { getUserInfo, getTenantInfo, updateUserInfo } from "services/api/config";
import { removeAllCookies, setCookie } from "utils/cookies";
import { Navigation } from "./Sidebar.styled";
import Tooltip from "components/ui/Tooltip";
import WhiteLogo from "assets/white_logo.svg";
import WhiteLogoNoName from "assets/white_logo_no_name.svg";
import EmailIngestionIcon from "assets/icons/email_ingestion_icon.svg";
import LogoutIcon from "assets/icons/logout_icon.svg";
import TrackingDeliveryIcon from "assets/icons/tracking_delivery_icon.svg";
import TrackingDeliveryActiveIcon from "assets/icons/tracking_delivery_active_icon.svg";
import RailTrackingDeliveryIcon from "assets/icons/rail_tracking_icon.svg";
import RailTrackingActiveDeliveryIcon from "assets/icons/rail_tracking_active_icon.svg";
import ContainerIcon from "assets/icons/Container-view-Wh.svg";
import ContainerIconActive from "assets/icons/container-view-Gr.svg";
import InsightsIcon from "assets/icons/insights_icon.svg";
import InsightsActiveIcon from "assets/icons/insights_active_icon.svg";
import KPIsIcon from "assets/icons/pie_icon.svg";
import KPIsActiveIcon from "assets/icons/pie_active_icon.svg";
import OceanViewIcon from "assets/icons/ocean_view_icon.svg";
import OceanViewIconActive from "assets/icons/ocean_view_active_icon.svg";
import OrgPic from "assets/images/org_pic.png";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import paths from "constants/paths";
import config from "config";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";

const { RAIL_TENANT_ID } = config.envs;

const Option = ({
  collapsed,
  icon,
  text,
  onClick,
}: {
  collapsed: boolean;
  icon: string;
  text: string;
  onClick: () => void;
}) => (
  <div className="option" onClick={onClick}>
    <div className="content">
      <img src={icon} alt="icon" className="icon" />
      {!collapsed && <span>{text}</span>}
    </div>
  </div>
);

const LanguageToggle = ({
  collapsed,
  langCode,
  currentLang,
  changeLanguage,
  labelCollapsed,
  labelExpanded,
}: {
  collapsed: boolean;
  langCode: string;
  currentLang: string;
  changeLanguage: (lang: string) => void;
  labelCollapsed: string;
  labelExpanded: string;
}) => (
  <div
    onClick={() => changeLanguage(langCode)}
    className={`lang ${langCode} ${currentLang === langCode ? "active" : ""}`}
  >
    {collapsed ? labelCollapsed : labelExpanded}
  </div>
);

type MenuItem = {
  name: string;
  path: string;
  icon: string;
  iconActive: string;
};

const menuItems: MenuItem[] = [
  {
    name: "Insights",
    path: paths.insights,
    icon: InsightsIcon,
    iconActive: InsightsActiveIcon,
  },
];

type SidebarProps = {
  collapsed: boolean;
  handleCollapse: () => void;
} & WithLoggedInAuthInfoProps;

const Sidebar = ({ collapsed, handleCollapse, accessToken }: SidebarProps) => {
  const [showTenantDropdown, setShowTenantDropdown] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const { activeTenant, setActiveTenant } = useActiveTenantStore();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogoutFunction();
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const featureFlags = useFeatureFlags(accessToken);

  // Fetch user and tenant info
  const { data: userInfo } = useQuery(["userInfo", accessToken], () => getUserInfo(accessToken));
  const { data: tenantInfo } = useQuery(["tenantInfo", accessToken], () =>
    getTenantInfo(accessToken),
  );

  // Handle outside click for dropdown
  const handleOutsideDropDownClick = (e: MouseEvent) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
      setShowTenantDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideDropDownClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideDropDownClick);
    };
  }, []);

  // Change language handler
  const changeLanguageHandler = async (lang: string) => {
    if (lang === language) return;
    try {
      changeLanguage(lang);
      await updateUserInfo(accessToken, { language: lang === "en" ? "english" : "spanish" });
    } catch (error) {
      changeLanguage(lang === "en" ? "es" : "en");
    }
  };

  // User and tenant info
  const userName = `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`.trim();
  const orgName = tenantInfo?.humanReadableName || tenantInfo?.name || "";
  const tenantId = tenantInfo?.id || "";
  const updatedMenuItems = useMemo(() => {
    const items = [...menuItems];
    if (featureFlags[FeatureFlagNames.EnableTripsFrontend]) {
      items.unshift({
        name: "Tracking",
        path: paths.tracking,
        icon: TrackingDeliveryIcon,
        iconActive: TrackingDeliveryActiveIcon,
      });
    }
    if (tenantId === RAIL_TENANT_ID) {
      items.push({
        name: "Rail Tracking",
        path: paths.railTracking,
        icon: RailTrackingDeliveryIcon,
        iconActive: RailTrackingActiveDeliveryIcon,
      });
    }
    if (featureFlags[FeatureFlagNames.EnableCargoUnitFrontend]) {
      items.unshift({
        name: "Cargo Unit",
        path: paths.cargoUnit,
        icon: ContainerIcon,
        iconActive: ContainerIconActive,
      });
    }
    if (featureFlags[FeatureFlagNames.EnableETALogsFrontend]) {
      items.push({
        name: "KPIs",
        path: paths.kpis,
        icon: KPIsIcon,
        iconActive: KPIsActiveIcon,
      });
    }
    if (featureFlags[FeatureFlagNames.EnableOceanViewPage]) {
      items.push({
        name: "Ocean View",
        path: paths.oceanView,
        icon: OceanViewIcon,
        iconActive: OceanViewIconActive,
      });
    }
    return items;
  }, [tenantId, featureFlags]);
  const profilePhotoDataUri = tenantInfo?.profilePhoto?.content
    ? `data:${tenantInfo.profilePhoto.contentType};base64,${tenantInfo.profilePhoto.content}`
    : OrgPic;

  const tenants = userInfo?.accessibleTenants || [];
  const activeTenantReadableName =
    activeTenant && tenants.find((tenant) => tenant.name === activeTenant)?.humanReadableName;

  return (
    <Navigation collapsed={collapsed}>
      <button className="collapseButton" onClick={handleCollapse}>
        {collapsed ? <ChevronRight fontSize="medium" /> : <ChevronLeft fontSize="large" />}
      </button>

      <div className="top">
        <span
          onClick={() => navigate(paths.tracking)}
          className={`logo ${collapsed ? "collapsed" : ""}`}
        >
          <img src={collapsed ? WhiteLogoNoName : WhiteLogo} alt="logo" />
        </span>

        <div className="orgInfo">
          <img
            className={`orgPic ${collapsed ? "collapsed" : ""}`}
            src={profilePhotoDataUri}
            alt="org-pic"
          />
          {!collapsed && (
            <>
              <div className="orgName">{orgName}</div>
              <span>
                {t("Welcome back")} <br /> {userName}
              </span>
            </>
          )}
        </div>

        <div className="menu">
          {updatedMenuItems.map((item, index) => (
            <div
              key={index}
              className={`menuItem ${location.pathname === item.path ? "active" : ""}`}
              onClick={() => navigate(item.path)}
            >
              {collapsed ? (
                <img
                  src={location.pathname === item.path ? item.iconActive : item.icon}
                  alt="icon"
                />
              ) : (
                <span>{t(item.name)}</span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="options">
        <Option
          collapsed={collapsed}
          icon={EmailIngestionIcon}
          text={t("Email Ingestion")}
          onClick={() => navigate(paths.emailIngestion)}
        />

        <Option
          collapsed={collapsed}
          icon={LogoutIcon}
          text={t("Log Out")}
          onClick={() => {
            removeAllCookies();
            if (tenantInfo?.logoutUrl) {
              setCookie("logoutUrl", tenantInfo?.logoutUrl);
            }
            logout(true);
          }}
        />

        {tenants && tenants.length > 1 && (
          <div
            className="dropDown"
            onClick={() => setShowTenantDropdown(!showTenantDropdown)}
            ref={dropDownRef}
          >
            <p>{t("Select Company")}</p>
            <Tooltip content={activeTenantReadableName} enabled={collapsed}>
              <button>{activeTenantReadableName}</button>
            </Tooltip>
            {showTenantDropdown && (
              <ul className="dropDownContent">
                {tenants.map(
                  (tenant) =>
                    tenant.name !== activeTenant && (
                      <li key={tenant.id} onClick={() => setActiveTenant(tenant.name)}>
                        {tenant.humanReadableName}
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        )}

        <div className="langs">
          <LanguageToggle
            collapsed={collapsed}
            langCode="en"
            currentLang={language}
            changeLanguage={changeLanguageHandler}
            labelCollapsed="En"
            labelExpanded="Eng"
          />
          <LanguageToggle
            collapsed={collapsed}
            langCode="es"
            currentLang={language}
            changeLanguage={changeLanguageHandler}
            labelCollapsed="Es"
            labelExpanded="Esp"
          />
        </div>
      </div>
    </Navigation>
  );
};

export default withAuthInfo(Sidebar);
