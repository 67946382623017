import styled from "@emotion/styled";

export const UntrackedCell = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  width: max-content;
`;

export const Message = styled.div<{ top: number; left: number }>`
  position: absolute;
  border-radius: 3px;
  background: #e5d0f0;
  padding: 3px 0px 3px 4px;
  opacity: 1;
  z-index: 1000;
  width: 171px;
  font-size: 10px;
`;
