import ArrivedIcon from "assets/icons/arrived_icon.svg";
import OceanInTransitIcon from "assets/icons/ocean_in_transit_icon.svg";
import OceanDischargedIcon from "assets/icons/v2/discharged_icon.svg";
import OceanBookedIcon from "assets/icons/booked_icon.svg";
import OceanLoadedIcon from "assets/icons/loaded_icon.svg";
import OceanPreArrivalIcon from "assets/icons/pre_arrival_icon.svg";
import CustomsPaidIcon from "assets/icons/v2/customs_paid_icon.svg";
import CustomsInspectionIcon from "assets/icons/v2/customs_inspection_icon.svg";
import CustomsReleasedIcon from "assets/icons/v2/customs_release_icon.svg";
import CustomsArrivedIcon from "assets/icons/v2/customs_icon.svg";
import CustomsOtherIcon from "assets/icons/v2/customs_other_icon.svg";
import CustomsValidatedIcon from "assets/icons/customs_validated_icon.svg";
import GroundInTransitIcon from "assets/icons/ground_in_transit_icon.svg";
import GroundLoadingIcon from "assets/icons/loading_icon.svg";
import GroundNotStartedIcon from "assets/icons/truck_icon.svg";
import AirInTransitIcon from "assets/icons/air_in_transit_icon.svg";

export const statuses: { [key: string]: string } = {
  InTransit: "In Transit",
  LoadedOnVessel: "Loaded on Vessel",
  Discharged: "Discharged",
  ArrivedInDestination: "Arrived in Destination",
  ToBeShipped: "To Be Shipped",
  ArrivalNotice: "Arrival Notice",
  PreArrival: "Pre Arrival",
  Loading: "Loading",
  Arrived: "Arrived",
  Booked: "Booked",
  Loaded: "Loaded",
  Paid: "Paid",
  Validated: "Validated",
  Inspection: "Inspection",
  Released: "Released",
  NotStarted: "Not Started",
  AwaitingData: "Awaiting Data",
  Completed: "Completed",
  Untracked: "Untracked",
  Departed: "Departed",
  Other: "Other",
} as const;

export const statusTransportIcons: {
  ocean: { [key: string]: string };
  customs: { [key: string]: string };
  ground: { [key: string]: string };
  air: { [key: string]: string };
} = {
  ocean: {
    [statuses.Booked]: OceanBookedIcon,
    [statuses.Loaded]: OceanLoadedIcon,
    [statuses.InTransit]: OceanInTransitIcon,
    [statuses.PreArrival]: OceanPreArrivalIcon,
    [statuses.Discharged]: OceanDischargedIcon,
    [statuses.Arrived]: ArrivedIcon,
  },
  customs: {
    [statuses.NotStarted]: CustomsArrivedIcon,
    [statuses.Paid]: CustomsPaidIcon,
    [statuses.Validated]: CustomsValidatedIcon,
    [statuses.Inspection]: CustomsInspectionIcon,
    [statuses.Released]: CustomsReleasedIcon,
    [statuses.Arrived]: CustomsArrivedIcon,
    [statuses.Other]: CustomsOtherIcon,
  },
  ground: {
    [statuses.NotStarted]: GroundNotStartedIcon,
    [statuses.Loading]: GroundLoadingIcon,
    [statuses.InTransit]: GroundInTransitIcon,
    [statuses.Arrived]: ArrivedIcon,
  },
  air: {
    [statuses.InTransit]: AirInTransitIcon,
    [statuses.Departed]: AirInTransitIcon,
    [statuses.Arrived]: ArrivedIcon,
  },
};
