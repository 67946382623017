import { useState, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { UntrackedCell, Message } from "./UntrackedTooltip.styled";

const UntrackedTooltip = ({
  children,
  enabled = false,
}: {
  children: React.ReactNode;
  enabled: boolean;
}) => {
  const { t } = useTranslation();
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(
    null,
  );
  const cellRef = useRef<HTMLDivElement | null>(null);

  // Calculate the tooltip position when hovered
  const handleMouseEnter = () => {
    if (cellRef.current) {
      const rect = cellRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + window.scrollY,
        left: 20 + rect.left + rect.width / 2,
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltipPosition(null);
  };

  if (!enabled) return <>{children}</>;

  return (
    <UntrackedCell
      ref={cellRef}
      className="untracked-cell"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}*
      {tooltipPosition &&
        createPortal(
          <Message className="tooltip" top={tooltipPosition.top} left={tooltipPosition.left}>
            {t("Dates marked with an asterisk (*) are estimated based on available information")}.
          </Message>,
          document.body,
        )}
    </UntrackedCell>
  );
};

export default UntrackedTooltip;
