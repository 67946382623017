import axios from "axios";
import config from "config";
import { getCookie } from "utils/cookies";

const apiClient = axios.create({
  baseURL: config.envs.BACKEND_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const tenantName = getCookie("activeTenant");
    if (tenantName) {
      config.headers["auba-tenant"] = tenantName;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default apiClient;
