import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import { upsertFilter } from "services/api/filters";
import SaveIcon from "assets/icons/save_icon.svg";

const SaveSearchButton = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const { uiState } = useInstantSearch();
  const { t } = useTranslation();

  const { mutate: saveUIState } = useMutation({
    mutationFn: () => upsertFilter(accessToken, JSON.stringify(uiState)),
  });

  const saveSearch = () => {
    saveUIState();
    window.location.reload();
  };

  return (
    <button type="button" onClick={saveSearch}>
      <img src={SaveIcon} alt="Save Icon" width={16} />
      {t("Save View")}
    </button>
  );
});

export default SaveSearchButton;
