import { useCallback, useRef, useState } from "react";
import {
  ColumnSizingState,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { useCargoUnitColumns } from "features/CargoUnit/hooks/useCargoUnitColumns";
import { useKeyScroll } from "features/CargoUnit/hooks/useKeyScroll";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "services/auth";
import { getTenantInfo } from "services/api/config";
import { CargoUnit } from "types/cargoUnitTypes";
import {
  TableCell,
  CargoUnitViewTable,
  TableRow,
  CargoUnitViewContainer,
} from "./CargoUnitTable.styled";
import TableHeaders from "../../../../components/TableHeader";

type CargoUnitTableParams = {
  cargoUnitData: CargoUnit[];
} & WithLoggedInAuthInfoProps;

const CargoUnitTable = withAuthInfo(({ accessToken, cargoUnitData }: CargoUnitTableParams) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const columnsSchema = useCargoUnitColumns();
  const { cargoUnitId: activeCargoUnitId, setCargoUnitId: setActiveCargoUnitId } =
    useCargoUnitStore();
  useKeyScroll(containerRef);

  const { data: tenantInfo } = useQuery(["tenantInfo", accessToken], () =>
    getTenantInfo(accessToken),
  );

  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(() => {
    const resultingState: ColumnSizingState = {};
    for (const { id, size } of columnsSchema) {
      if (id && size) {
        resultingState[`${id}`] = size;
      }
    }
    return resultingState;
  });

  const handleOnColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback((value) => {
    setColumnSizing((state) => {
      return {
        ...state,
        ...(typeof value === "function" ? value?.(state) : value),
      };
    });
  }, []);

  const table = useReactTable({
    data: cargoUnitData,
    columns: columnsSchema,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    defaultColumn: {
      size: 150,
      minSize: 80,
    },
    initialState: {
      columnPinning: {
        left: ["cargoId", "currentModeOfTransport"],
        right: [],
      },
      columnVisibility: {
        id: false,
        division: tenantInfo?.usesDivisions ? true : false,
      },
    },
    onColumnSizingChange: handleOnColumnSizingChange,
    state: {
      columnSizing,
    },
  });

  function openCargoUnitCard(cargoUnitId: string) {
    // Prevent opening card when text is selected
    const selectedText = window.getSelection()?.toString();
    if (selectedText) return;

    setActiveCargoUnitId(cargoUnitId);
  }

  return (
    <CargoUnitViewContainer
      data-testid="cargo-unit-table"
      style={{ overflowX: "scroll" }}
      ref={containerRef}
    >
      <CargoUnitViewTable tableWidth={table.getTotalSize()}>
        <TableHeaders table={table} />
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => openCargoUnitCard(row.getValue("id"))}
              className={activeCargoUnitId === row.getValue("id") ? "active" : ""}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  columnWidth={cell.column.getSize()}
                  columnStart={cell.column.getStart("left")}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </CargoUnitViewTable>
    </CargoUnitViewContainer>
  );
});

export default CargoUnitTable;
