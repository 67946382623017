import styled from "@emotion/styled";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 3px;
  justify-content: space-between;
  width: 100%;

  button {
    min-width: 38px;
    height: 39px;
    display: flex;
    justify-content: center;
    border: 1px solid #e9e9ee;
    align-items: center;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    position: relative;
    gap: 6px;
    padding: 12px;

    &:hover {
      background: #f4f4f4;
    }

    &.active {
      background: #f4f4f4;

      &:hover {
        background: #e9e9ee;
      }
    }

    &:active {
      background: #e9e9ee;
      border-color: #e9e9ee;
    }

    &:disabled {
      cursor: not-allowed;
      background: #f4f4f4;
      border-color: #f4f4f4;
    }

    &.excelButton {
      .hover {
        display: none;
        position: absolute;
        z-index: 99;
      }

      &:hover .hover {
        display: inline;
        left: 12px;
      }

      &:active {
        border-color: #10934d;
        background: #f9fefb;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
  justify-content: start;

  > div {
    flex-basis: 60%;
  }
`;
