import config from "config";
import TripCardModal from "features/TripCardModal";
import { TrackingRecord } from "types/trackingTypes";
import { Container, Table } from "./TrackingTable.styled";
import TableHeader from "../TableHeader";
import SectionTable from "../SectionTable";
import SearchPagination from "../../../../components/SearchPagination/SearchPagination";

const { USE_BACKEND_RECORDS } = config.envs;

type TrackingTableProps = {
  trackingRecords: TrackingRecord[];
};

const TrackingTable = ({ trackingRecords }: TrackingTableProps) => (
  <>
    <Container>
      <Table data-testid="tracking-table">
        <TableHeader />
        <SectionTable records={trackingRecords} />
      </Table>
    </Container>
    {USE_BACKEND_RECORDS !== "true" && <SearchPagination />}
    <TripCardModal />
  </>
);

export default TrackingTable;
