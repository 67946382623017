import { useDocumentTitle } from "hooks/useDocumentTitle";
import { Container } from "./KpisPage.styled";
import KPIs from "assets/images/kpis.png";

const KpisPage = () => {
  useDocumentTitle("KPIs");

  return (
    <Container>
      <h1>KPIs</h1>
      <div className="content">
        <img src={KPIs} alt="KPIs" />
      </div>
    </Container>
  );
};

export default KpisPage;
