import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          Archived: "Archived",
          Vendor: "Vendor",
          Carrier: "Carrier",
          Documents: "Documents",
          Reference: "Reference",
          Container: "Container",
          Destination: "Destination",
          "Email Ingestion": "Email Ingestion",
          "Log Out": "Log Out",
          "Please select your email provider": "Please select your email provider",
          Provider: "Provider",
          "Oops! Looks like something went wrong. Please restart the authorization flow.":
            "Oops! Looks like something went wrong. Please restart the authorization flow.",
          "Authorization for email ingestion succeeded!":
            "Authorization for email ingestion succeeded!",
          "Processing...": "Processing...",
          "BL Number": "BL Number",
          "Bills of Lading": "Bills of Lading",
          "BL Date": "BL Date",
          Origin: "Origin",
          "Current Mode of Transport": "Current Mode of Transport",
          Mode: "Mode",
          Ship: "Ship",
          Ocean: "Ocean",
          Ground: "Ground",
          Customs: "Customs",
          Air: "Air",
          Rail: "Rail",
          "Customs Broker": "Customs Broker",
          Status: "Status",
          "Original ETA": "Original ETA",
          "Current ETA": "Current ETA",
          "ETA Delay": "ETA Delay",
          Tracking: "Tracking",
          Untracked: "Untracked",
          "To Be Shipped": "To Be Shipped",
          "Loaded on Vessel": "Loaded on Vessel",
          "In Transit": "In Transit",
          "Arrival Notice": "Arrival Notice",
          Arrived: "Arrived",
          "Arrived In Destination": "Arrived in Destination",
          Discharged: "Discharged",
          "Discharged from Vessel": "Discharged from Vessel",
          "day(s)": "day(s)",
          "Journey Start": "Journey Start",
          "Shipping Line": "Shipping Line",
          "Welcome back": "Welcome back",
          Loading: "Loading",
          "An error occurred. Please try again later": "An error occurred. Please try again later",
          "Updated ETA": "Updated ETA",
          "Transport Type": "Transport Type",
          day: "day",
          "New ETA": "New ETA",
          Cargo: "Cargo",
          "Cargo Number": "Cargo Number",
          "Pre Arrival": "Pre Arrival",
          "The file cannot be displayed in your browser":
            "The file cannot be displayed in your browser",
          Upload: "Upload",
          Export: "Export",
          Trips: "Trips",
          "What are you looking for?": "What are you looking for?",
          Start: "Start",
          Contents: "Contents",
          "Uploading your files": "Uploading your files",
          [`Please be patient, this may take a few minutes.
            We will send you an email once the process is complete.
            You may close this message.`]: `Please be patient, this may take a few minutes.
            We will send you an email once the process is complete.
            You may close this message.`,
          Filter: "Filter",
          "Add Filter To Trips": "Add Filter To Trips",
          "Add Filter To Legs": "Add Filter To Legs",
          "Active Leg Mode Of Transport": "Active Leg Mode Of Transport",
          "Origin City": "Origin City",
          "Destination City": "Destination City",
          City: "City",
          Country: "Country",
          "Select Company": "Select Company",
          "No options available": "No options available",
          "Active Filters": "Active Filters",
          "Document Not Yet Received": "Document Not Yet Received",
          "We have not yet received the": "We have not yet received the",
          "for this leg.": "for this leg.",
          Collapse: "Collapse",
          Snooze: "Snooze",
          "Not interested": "Not interested",
          Dismiss: "Dismiss",
          Alerts: "Alerts",
          "This Pedimento is now paid. No more action needed":
            "This Pedimento is now paid. No more action needed",
          "This Pedimento has not been paid according to SOIA Net and is due to arrive to the port of destination in less than two days":
            "This Pedimento has not been paid according to SOIA Net and is due to arrive to the port of destination in less than two days",
          AlertETADelayDescription:
            "The estimated time of arrival has been updated to {{days}} days later than expected",
          "Pedimento has been paid": "Pedimento has been paid",
          "Pedimento has not been paid": "Pedimento has not been paid",
          "+10 Days Delay": "+10 Days Delay",
          "No Delay": "No Delay",
          Insights: "Insights",
          Showing: "Showing",
          "out of": "out of",
          shipments: "shipments",
          "Show More": "Show More",
          "Show Less": "Show Less",
          Division: "Division",
          End: "End",
          "Released from Customs": "Released from Customs",
          Departed: "Departed",
          Delivered: "Delivered",
          "Invoice Number": "Invoice Number",
          Invoices: "Invoices",
          "Rail Tracking": "Rail Tracking",
          "Preview not supported for this file type yet":
            "Preview not supported for this file type yet",
          Download: "Download",
          "Purchase Order": "Purchase Order",
          "Departure Country": "Departure Country",
          "Departure City": "Departure City",
          "Endpoint Country": "Endpoint Country",
          "Endpoint City": "Endpoint City",
          "Endpoint BL": "Endpoint BL",
          "Departure Date": "Departure Date",
          "Entry City": "Entry City",
          "Entry BL": "Entry BL",
          "Entry Carrier": "Entry Carrier",
          "Entry Original ETA": "Entry Original ETA",
          "Entry Current ETA": "Entry Current ETA",
          "Entry ATA": "Entry ATA",
          "Entry Mode of Transport": "Entry Mode of Transport",
          "Customs Document Number": "Customs Document Number",
          "Customs Release ETA": "Customs Release ETA",
          "Customs Release ATA": "Customs Release ATA",
          "Endpoint Carrier": "Endpoint Carrier",
          "Endpoint Original ETA": "Endpoint Original ETA",
          "Endpoint Current ETA": "Endpoint Current ETA",
          "Endpoint ATA": "Endpoint ATA",
          "Days Without Delay": "Days Without Delay",
          "Container Return Date": "Container Return Date",
          "See ETA Logs": "See ETA Logs",
          "Container Contents": "Container Contents",
          Milestones: "Milestones",
          "Save View": "Save View",
          "Dates marked with an asterisk (*) are estimated based on available information":
            "Dates marked with an asterisk (*) are estimated based on available information",
          "Entered Customs": "Entered Customs",
          "Vessel Name": "Vessel Name",
          "Container Number": "Container Number",
          "Bill of Lading Numbers": "Bill of Lading Numbers",
          "Latest Milestone": "Latest Milestone",
          "Purchase Orders": "Purchase Orders",
          "Invoice Numbers": "Invoice Numbers",
          "Port of Departure": "Port of Departure",
          "Destination Country": "Destination Country",
          "Estimated Departure Date": "Estimated Departure Date",
          "Actual Departure Date": "Actual Departure Date",
          "Original Discharge ETA": "Original Discharge ETA",
          "Current Discharge ETA": "Current Discharge ETA",
          "Port of Destination ATA": "Port of Destination ATA",
          "Actual Discharge Date": "Actual Discharge Date",
          "Total ETA Delay (days)": "Total ETA Delay (days)",
        },
      },
      es: {
        translation: {
          Archived: "Archivado",
          Vendor: "Proveedor",
          Carrier: "Transportador",
          Documents: "Documentos",
          Reference: "Referencia",
          Container: "Contenedor",
          Destination: "Destino",
          "Email Ingestion": "Ingestión de correo",
          "Log Out": "Cerrar sesión",
          "Please select your email provider": "Seleccione su proveedor de correo electrónico",
          Provider: "Proveedor",
          "Oops! Looks like something went wrong. Please restart the authorization flow.":
            "Oops! Parece que algo salió mal. Vuelva a iniciar la autorización.",
          "Authorization for email ingestion succeeded!":
            "¡La autorización de ingestión de correo electrónico tuvo éxito!",
          "Processing...": "Procesando...",
          "BL Number": "Número de BL",
          "Bills of Lading": "Bills of lading",
          "BL Date": "Fecha de BL",
          Origin: "Origen",
          "Current Mode of Transport": "Modo de transporte actual",
          Mode: "Modo",
          Ship: "Barco",
          Ocean: "Marítimo",
          Ground: "Terrestre",
          Customs: "Aduana",
          Air: "Aereo",
          Rail: "Ferroviario",
          "Customs Broker": "Agente aduanal",
          Status: "Estado",
          "Original ETA": "Original ETA",
          "Current ETA": "Actual ETA",
          "ETA Delay": "ETA Retraso",
          Tracking: "Seguimiento",
          Untracked: "Sin seguimiento",
          "To Be Shipped": "Por enviar",
          "Loaded on Vessel": "Cargado en barco",
          "In Transit": "En tránsito",
          "Arrival Notice": "Aviso de llegada",
          Arrived: "Llegó",
          "Arrived In Destination": "Llegó al destino",
          Discharged: "Descargado",
          "Discharged from Vessel": "Descargado de Barco",
          "day(s)": "día(s)",
          "Journey Start": "Inicio del viaje",
          "Shipping Line": "Línea de envío",
          "Welcome back": "Bienvenido de nuevo",
          Loading: "Cargando",
          "An error occurred. Please try again later":
            "Ocurrió un error. Por favor, inténtelo de nuevo más tarde",
          "Updated ETA": "ETA actualizada",
          "Transport Type": "Tipo de Transporte",
          day: "día",
          "New ETA": "Nueva ETA",
          Cargo: "Carga",
          "Cargo Number": "Número de carga",
          "Pre Arrival": "Pre llegada",
          "The file cannot be displayed in your browser":
            "El archivo no se puede mostrar en su navegador",
          Upload: "Subir",
          Export: "Exportar",
          Trips: "Viajes",
          "What are you looking for?": "¿Qué estás buscando?",
          Start: "Inicio",
          Contents: "Contenidos",
          "Uploading your files": "Subiendo tus archivos",
          [`Please be patient, this may take a few minutes.
            We will send you an email once the process is complete.
            You may close this message.`]:
            `Por favor, tenga paciencia, esto puede tardar unos minutos.
            Le enviaremos un correo electrónico una vez que el proceso esté completo. Puede cerrar este mensaje.`,
          Filter: "Filtrar",
          "Add Filter To Trips": "Agregar Filtro A Viajes",
          "Add Filter To Legs": "Agregar Filtro A Tramos",
          "Active Leg Mode Of Transport": "Modo De Transporte De Tramo Activo",
          "Origin City": "Ciudad Do Origen",
          "Destination City": "Ciudad De Destino",
          City: "Ciudad",
          Country: "País",
          "Select Company": "Seleccionar Compañía",
          "No options available": "Sin opciones",
          "Active Filters": "Filtros Activos",
          "Document Not Yet Received": "Documento No Recibido Aún",
          "We have not yet received the": "Aún no hemos recibido el",
          "for this leg.": "para este tramo.",
          Collapse: "Colapsar",
          Snooze: "Posponer",
          "Not interested": "No interesado",
          Dismiss: "Descartar",
          Alerts: "Alertas",
          "This Pedimento is now paid. No more action needed":
            "Este pedimento ya está pagado. No se necesita más acción",
          "This Pedimento has not been paid according to SOIA Net and is due to arrive to the port of destination in less than two days":
            "Este pedimento no ha sido pagado según SOIA Net y debe llegar al puerto del destino en menos de dos días",
          AlertETADelayDescription:
            "La hora estimada de llegada se ha actualizado a {{days}} días más tarde de lo esperado",
          "Pedimento has been paid": "El pedimento ha sido pagado",
          "Pedimento has not been paid": "El pedimento no ha sido pagado",
          "+10 Days Delay": "+10 Días de Retraso",
          "No Delay": "Sin Retraso",
          Insights: "Informes",
          Showing: "Mostrando",
          "out of": "de",
          shipments: "envíos",
          "Show More": "Mostrar Más",
          "Show Less": "Mostrar Menos",
          Division: "División",
          End: "Fin",
          "Released from Customs": "Liberado de Aduana",
          Departed: "Salió",
          Delivered: "Entregado",
          "Invoice Number": "Número de factura",
          Invoices: "Facturas",
          "Rail Tracking": "Localización de Carros",
          "Preview not supported for this file type yet":
            "Vista previa aún no disponible para este tipo de archivo",
          Download: "Descargar",
          "Purchase Order": "Orden de compra",
          "Departure Country": "País de salida",
          "Departure City": "Ciudad de salida",
          "Endpoint Country": "País de destino",
          "Endpoint City": "Ciudad de destino",
          "Endpoint BL": "BL de destino",
          "Departure Date": "Fecha de salida",
          "Entry City": "Ciudad de entrada",
          "Entry BL": "BL de entrada",
          "Entry Carrier": "Transportador de entrada",
          "Entry Original ETA": "ETA original de entrada",
          "Entry Current ETA": "ETA actual de entrada",
          "Entry ATA": "ATA de entrada",
          "Entry Mode of Transport": "Modo de transporte de entrada",
          "Customs Document Number": "Número de documento de aduana",
          "Customs Release ETA": "ETA de liberación de aduana",
          "Customs Release ATA": "ATA de liberación de aduana",
          "Endpoint Carrier": "Transportador de destino",
          "Endpoint Original ETA": "ETA original de destino",
          "Endpoint Current ETA": "ETA actual de destino",
          "Endpoint ATA": "ATA de destino",
          "Days Without Delay": "Días sin retraso",
          "Container Return Date": "Fecha de retorno de contenedor",
          "See ETA Logs": "Ver registros de ETA",
          "Container Contents": "Contenido del contenedor",
          Milestones: "Hitos",
          "Save View": "Guardar Vista",
          "Dates marked with an asterisk (*) are estimated based on available information":
            "Las fechas marcadas con un asterisco (*) son estimadas en base a la información disponible",
          "Entered Customs": "Entró a aduana",
          "Vessel Name": "Nombre del Barco",
          "Container Number": "Número de Contenedor",
          "Bill of Lading Numbers": "Números de BL",
          "Latest Milestone": "Último Hito",
          "Purchase Orders": "Órdenes de Compra",
          "Invoice Numbers": "Números de Factura",
          "Port of Departure": "Puerto de Salida",
          "Destination Country": "País de Destino",
          "Estimated Departure Date": "Fecha de Salida Estimada",
          "Actual Departure Date": "Fecha de Salida Actual",
          "Original Discharge ETA": "ETA de Descarga Original",
          "Current Discharge ETA": "ETA de Descarga Actual",
          "Port of Destination ATA": "ATA de Puerto de Destino",
          "Actual Discharge Date": "Fecha de Descarga Actual",
          "Total ETA Delay (days)": "Retraso Total de ETA (días)",
        },
      },
    },
  });

export default i18n;
