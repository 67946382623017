import styled from "@emotion/styled";

export const Container = styled.div`
  overflow-x: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    display: none;
  }
  &:hover::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #aaa;
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #555;
  }
  &::-webkit-scrollbar-track:horizontal {
    background-color: #f1f1f1;
  }
`;

export const Table = styled.table<{ tableWidth: number }>`
  width: ${(props) => props.tableWidth}px;
  border-collapse: separate;
  margin-top: 20px;
  font-size: 15px;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    position: sticky;
    z-index: 2;
    background-color: white;
    top: 0;
  }
`;

export const TableCell = styled.td<{ columnWidth: number; columnStart: number }>`
  width: ${(props) => props.columnWidth}px;
  background-color: white;
  border-right: 1px solid #e9e9ee;
  border-bottom: 1px solid #e9e9ee;
  border-left: 1px solid #e9e9ee;
  padding-left: 10px;
  overflow: auto;

  &:first-of-type {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
  }
  &:nth-of-type(2) {
    margin-left: 0;
    position: sticky;
    left: ${(props) => props.columnStart}px;
    z-index: 1;
    background-color: white;
  }
`;

export const TableRow = styled.tr`
  cursor: pointer;

  &:hover td {
    background: #f7f3ff;
  }

  &.active {
    background: #f7f3ff;

    td {
      background: #f7f3ff;
    }
  }
`;
