import { create } from "zustand";

type CargoUnitStore = {
  cargoUnitId: string | null;
  setCargoUnitId: (cargoUnitId: string | null) => void;
  clearCargoUnitId: () => void;
};

export const useCargoUnitStore = create<CargoUnitStore>((set) => ({
  cargoUnitId: null,
  setCargoUnitId: (cargoUnitId) => set({ cargoUnitId }),
  clearCargoUnitId: () => set({ cargoUnitId: null }),
}));
