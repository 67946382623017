import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Container, Wrapper } from "./ActionButtons.styled";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";

const ActionButtons = ({ title, children }: ActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="action-buttons">
      <h1>{t(title)}</h1>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

interface ActionButtonsProps extends WithLoggedInAuthInfoProps {
  title: string;
  children: ReactNode;
}

export default withAuthInfo(ActionButtons);
