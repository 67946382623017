import SearchPagination from "components/SearchPagination";
import CargoUnitTable from "features/CargoUnit/components/CargoUnitTable";
import { useTranslation } from "react-i18next";
import { useInfiniteHits } from "react-instantsearch";
import { sanitizeAlgoliaCargoUnits } from "utils/sanitizeAlgoliaRecords";

const AlgoliaCargoUnitTable = () => {
  const { items } = useInfiniteHits();
  const {
    i18n: { language },
  } = useTranslation();

  const cargoUnitRecords = sanitizeAlgoliaCargoUnits(items, language);

  return (
    <>
      <CargoUnitTable cargoUnitData={cargoUnitRecords} />
      <SearchPagination />
    </>
  );
};

export default AlgoliaCargoUnitTable;
