import styled from "@emotion/styled";

export const Drawer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  transition: width 0.4s ease;
  overflow: hidden;
  border-top-left-radius: 25px;
  display: flex;
  border-radius: 16px 0px 0px 16px;
  background: var(--Primary-Base-White, #fff);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 2;
`;

export const DrawerContent = styled.div`
  padding: 33px 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;

  .documents {
    height: 242px;
    overflow: auto;
    font-size: 14px;

    .header {
      display: flex;
      justify-content: flex-end;
      padding-right: 47px;
      cursor: pointer;
      margin-bottom: 5px;
    }

    .documentsList {
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
          background: #f5f3f3;
        }

        &.active {
          background: #f5f3f3;
        }
      }
      img {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &.open {
          transform: rotate(90deg);
        }
        &.fileIcon {
          padding-right: 5px;
          width: 14px;
          height: 11px;
        }
      }
      .label {
        margin-right: 17px;
        margin-left: 19px;
      }
      .documentType {
        background: #f8f8f8;
        font-size: 15px;
        height: 37px;
        box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
      }
      .referenceId {
        gap: 3px;
        height: 23px;
      }
      .fileName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 23px;
        padding-left: 40px;
        padding-right: 57px;
      }
    }
  }
  .vendor {
    .header {
      display: flex;
    }

    .row {
      display: flex;
      font-size: 16;
    }

    .column {
      padding: 0px 10px;
      display: flex;
      flex: 1;
      align-items: left;
      overflow: hidden;
      width: 220px;
    }
  }

  .documents,
  .vendor {
    box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
    background: #fff;
    border: 1.072px solid #e9e9ee;
    border-radius: 25.739px;
    padding: 17px 21px;
    max-width: 100%;
    min-width: 340px;
  }
`;

export const CargoUnitSection = styled.div`
  padding: 0px;
  border-radius: 25.739px;
  border: 1.072px solid #e9e9ee;
  background: #fff;
  box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
  height: 220px;
  overflow: auto;

  .header {
    display: flex;
    justify-content: space-between;
    height: 47px;
    position: sticky;
    top: 0;
    background: #fff;

    .column {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }

  .cargoUnitList {
    cursor: pointer;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 47px;

      &.active {
        border: 1px solid #cacaca;
        background: #eaecf0;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      }
      .column {
        display: flex;
        justify-content: center;
        img {
          padding-right: 5px;
        }
        &.ata {
          align-items: center;
          color: #5e5e5e;
          width: 120px;
          padding-right: 20px;
        }
        &.eta {
          margin-right: 60px;
        }
        &.containerNumber {
          padding-left: 20px;
          width: 120px;
        }
      }
      .ata {
        margin-left: 20px;
      }
      .tag {
        font-size: 10px;
        padding: 0px 6.637px 0px 7.111px;
        max-width: 92px;
        text-align: center;
        border-radius: 2px;
        border: 0.882px solid #e2e2e2;
        border-color: #e1e1e1;
        font-weight: 700;
        background: #cdcdcd;
        margin-right: 20px;
      }
    }
  }
`;

export const Alert = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid var(--Text-Dark, #d5d5d5);
  background: var(--Primary-Base-White, #fff);

  .eventAlert {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;

    &.recent {
      .alertTitle {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        height: 55px;
        font-weight: 600;
      }
    }

    .alertTitle {
      border-radius: 10px;
      padding: 5px 10px;
      line-height: 0;
      display: flex;
      height: 45px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      font-size: 16px;
      font-weight: 400;

      &.alert {
        background: rgba(239, 56, 47, 0.1);

        .iconBlock {
          background: #e65f2b;
        }
      }

      &.resolve {
        background: rgba(0, 165, 155, 0.1);

        .iconBlock {
          background: #70a1e5;
        }
      }

      .iconBlock {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
      }
    }

    .alertDescription {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 20px;
    }
  }

  .breakLine {
    width: 504px;
    border: 0.928px solid #e6e6e8;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-self: stretch;

    button {
      display: flex;
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 3.087px;
      align-self: stretch;
      border-radius: 13px;
      border: 0.617px solid #cdcdcd;
      background: #fafafa;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      img {
        height: 11px;
      }

      &:hover {
        background: #f5f5f5;
      }

      &:active {
        background: #e9e9e9;
      }
    }
  }
`;

export const AlertsSection = styled.div`
  padding: 20px 13px;
  border-radius: 22.262px;
  border: 0.928px solid var(--Stroke-table, #e9e9ee);
  background: var(--Primary-Base-White, #fff);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;

  .alerts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    overflow-y: auto;
  }
`;

export const AlertsTitlesSection = styled.div`
  overflow: auto;
  cursor: pointer;
  box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  border: 1.072px solid #e9e9ee;
  border-radius: 25.739px;
  padding: 17px 21px;
  max-width: 100%;
  min-width: 340px;
`;

export const AlertsHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 0;
  color: #231f20;

  .numberOfAlerts {
    text-transform: lowercase;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ef382f;
    font-size: 12px;
    color: #fff;
  }
`;

export const ETALogsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  border: 1px solid #e9e9ee;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e9e9e9;
  }
`;

export const LogsViewer = styled.div`
  height: 80vh;
  border-radius: 16px;
  border: 1px solid #cacaca;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .content {
    height: 80vh;
  }

  img {
    width: 100%;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    background: #f5f5f5;
    height: 31px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    // border radius left top right top
    border-radius: 16px 16px 0px 0px;
  }

  .footer {
    height: 31px;
    background: #f5f5f5;
    border-radius: 0px 0px 16px 16px;
  }
`;
